body {
  margin: 0;
  font-size: 0.9rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-family: 'Roboto', sans-serif; /* Utilise une police moderne comme Roboto */
  font-size: 2rem; /* Taille de police agréable, ajustable selon les besoins */
  color: #333; /* Une couleur de texte subtile et professionnelle */
  font-weight: 600; /* Gras mais pas trop épais pour un look professionnel */
  letter-spacing: 0.5px; /* Espacement léger entre les lettres pour un effet élégant */
  text-align: center; /* Centre le texte, ajustable en fonction de la mise en page */
  padding: 10px 0; /* Un espacement autour pour mieux respirer */
  border-bottom: 2px solid #007bff; /* Ajoute une bordure fine sous le titre */
  margin-bottom: 20px; /* Espace entre le titre et les autres éléments */
  position: relative; /* Pour ajouter des effets si nécessaire */
}

h2::after {
  content: '';
  width: 60px; /* Longueur de la ligne sous le titre */
  height: 4px; /* Épaisseur de la ligne */
  background-color: #007bff; /* Une couleur moderne et marquante pour le site de gestion */
  display: block;
  margin: 10px auto 0; /* Centrage et espace autour de la ligne */
  border-radius: 2px; /* Coins légèrement arrondis */
}
.dropzone {
  border: 2px dashed #007bff;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.list-group-item {
  border-color:  darkgray;
}

.modal-70w {
  max-width: 70%;
}

.expertise-title {
  text-align: center;
  color: #007bff; /* Bleu Bootstrap "primary" */
  margin-bottom: 20px;
  padding-top: 20px;
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 2px solid #dee2e6; /* Ligne subtile sous le titre */
  padding-bottom: 10px;
}

#custom-checkbox .form-check-input {
  cursor: pointer; /* Makes the mouse cursor a pointer when hovering over the checkbox */
}

#custom-checkbox .form-check-input:checked {
  background-color: #0d6efd; /* Blue background for checked state */
  border-color: #0d6efd;
}

#custom-checkbox .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); /* Adds a blue glow around the checkbox when focused */
}

#custom-checkbox .form-check-input:disabled {
  background-color: #e9ecef; /* Gray background for disabled state */
  cursor: not-allowed;
}

#custom-checkbox .form-check-label {
  cursor: pointer; /* Ensures the label also triggers the checkbox */
}
.Auth-form-container {
  min-width: 200px; /* Ensures the form is not too narrow */
}
@media (min-width: 768px) {
  .Auth-form-container {
    transform: translateY(-50px);
  }
}

@media (max-width: 767px) {
  .Auth-form-container {
    transform: translateY(-20px); /* moins d'ajustement sur les petits écrans */
  }
}


.Auth-form {
  width: 100%;
  max-width: 400px;
  padding: 15px;
}

.Auth-form-title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.buttonStyle {
  display: inline-block;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}
.text-right {
  text-align: right;
}
.table-container {
  overflow-x: auto; /* Permet un défilement horizontal */
  position: relative;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Assure que les colonnes respectent les tailles définies */
}

th, td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.fixed-column-left {
  position: sticky;
  left: 0; /* Fixe la colonne à gauche */
  background-color: #f9f9f9; /* Assure que le fond est cohérent */
  width: 5%; /* Représente 1/5 de la largeur totale */
}
.fixed-column {
  position: sticky;
  right: 0; /* Fixe la colonne à droite */
  background-color: #f9f9f9; /* Assure que le fond est cohérent */
  width: 15%; /* Représente 1/5 de la largeur totale */
}

th.fixed-column, td.fixed-column {
  z-index: 2; /* Priorité d'affichage pour éviter les chevauchements */
}
.wide-column {
  width: 55%; /* Ajustez la largeur en fonction de vos besoins */
}




