.sticky-nav {
  position: -webkit-sticky; /* Pour le support Safari */
  position: sticky;
  top: 0; /* Définissez la distance du haut de la fenêtre de visualisation lorsque vous commencez à coller */
  height: 50vh; /* Hauteur pour garantir que toute la colonne est visible */
  overflow-y: auto; /* Permet de faire défiler la navigation si elle dépasse l'écran */
}
@media (max-width: 768px) {
  .sticky-nav {
      position: relative;
      height: auto;
      overflow-y: visible;
  }
}
.fixed-nav {
    position: fixed; /* Fixed position relative to the viewport */
    top: 50; /* Top edge at the top of the viewport */
    left: 0; /* Left edge at the start of the viewport */
    width: 16.66666667%; /* Equivalent to 'col-md-2' in Bootstrap grid */
    max-width: 16.66666667%; /* Ensure it matches the width settings from Bootstrap */
    height: 50vh; /* Full viewport height */
    overflow-y: auto; /* Allows scrolling within the navigation column if it exceeds the viewport height */
}
